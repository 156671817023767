<template>
  <v-row fill-height align="center" justify="center" no-gutters>
    <v-col cols="6">
      <v-card flat>
        <v-card-text class="d-flex justify-center">
          <v-text-field solo :placeholder="$i18n.t('placeHolder')" rounded height="55px" class="text-h5 containerBorder"
            hide-details :append-icon="expand ? '' : 'mdi-magnify'" @click:append-outer="expand = false"
            @click="expand = true" :value="searchKeyword" v-model="searchKeyword" clearable>
          </v-text-field>
          <v-icon size="35" class="ml-2" v-if="expand" @click="expand = !expand;">mdi-keyboard-off-outline</v-icon>
        </v-card-text>
        <v-expand-transition>
          <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
            <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="searchKeyword" class="keyboard">
            </SimpleKeyboard>
          </v-card-text>
        </v-expand-transition>
      </v-card>
    </v-col>
    <v-col cols="12" align="center" @click="expand = false">
      <v-card class="pa-4 containerBorder" width="95%" flat>
        <v-data-table hide-default-footer :items-per-page="-1" :height="viewBoxHeight" :headers="header" :items="list"
          :loading="loading" loading-text="Fetching latest data. Please wait..." fixed-header>
          <template v-slot:item.caseNumber="{ item }">
            <strong>{{ item.caseNumber }}</strong>
          </template>
          <template v-slot:item.partyName="{ item }">
            <div class="my-7" v-if="item.partyName === ''"> - </div>
            <div class="my-7" v-else>
              {{ item.partyName }}
            </div>
          </template>
          <!-- <template v-slot:item.judgeName="{ item }">
            <span v-if="item.judgeName === undefined"> - </span>
            <span v-else>Judge {{ item.judgeName }}</span>
          </template> -->
          <template v-slot:item.hearingLocation="{ item }">
            <v-btn @click="handleCourtLocation(item.hearingLocation)" width="100%" elevation="0" color="primary">
              <v-icon left>mdi-gesture-tap</v-icon>
              {{ item.hearingLocation | trimLength }} - {{ item.floorName }}
            </v-btn>
          </template>
          <template v-slot:item.hearingTime="{ item }">
            <span>{{ item.hearingTime | dateAndTime }}</span>
          </template>
        </v-data-table>
        <v-card-text class="ma-0 pb-0">
          <span class="mr-5 text-h6">{{ $t('noCase') }} </span>
          <v-btn color="primary" class="ml-5" @click="showMap()"><v-icon left>mdi-gesture-tap</v-icon>{{
            getDefaultLocation
            }}</v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SimpleKeyboard from "@/components/SimpleKeyboard";
import { mapGetters } from "vuex";
import moment from "moment";
import MiniSearch from 'minisearch'
export default {
  name: "hearingList",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      expand: false,
      searchKeyword: null,
      viewBoxHeight: 600,
      loading: true,
      header: [
        {
          text: "Case Number",
          align: "center",
          value: "caseNumber",
          width: "200",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: "Parties Name",
          align: "start",
          value: "partyName",
          width: "750",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        // {
        //   text: "Judge Name",
        //   align: "center",
        //   value: "judgeName",
        //   sortable: false,
        //   class: "text-h6 font-weight-black text-decoration-underline",
        // },
        {
          text: "Court Room Location",
          align: "center",
          value: "hearingLocation",
          width: "300",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
        {
          text: "Date/Time",
          align: "center",
          value: "hearingTime",
          sortable: false,
          class: "text-h6 font-weight-black text-decoration-underline",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["hearingInfo", "getMaps", "getDefaultLocation"]),
    list() {
      if (this.searchKeyword === "" || this.searchKeyword === null) {
        return this.hearingInfo.slice().sort(this.customSort);
      } else {
        console.log('Hearing info: ', this.hearingInfo)
        let miniSearch = new MiniSearch({
          fields: ['partyName', 'caseNumber'],
          storeFields: ['partyName', 'caseNumber', 'hearingTime', 'hearingLocation', 'floorName'],
          searchOptions: {
            fuzzy: 0.2,
            prefix: true
          }
        })

        miniSearch.addAll(this.hearingInfo)

        let results = miniSearch.search(this.searchKeyword.toLowerCase())
        console.log('Result on search: ', results)
        return results
      }
    },
  },
  watch: {
    expand(n, o) {
      this.$store.commit('setRequestQna', false)
      this.$store.commit("setIsThinking", false)
      this.$store.commit("setSpeechRecognitionHandler", false);
      if (n) {
        this.viewBoxHeight = 370;
      } else {
        this.viewBoxHeight = 600;
        //user session logging
        this.$store.commit("setTouchRequest", {
          module: "Todays Hearing",
          action: `Search keyword - " ${this.searchKeyword} "`,
          response: `Found ${this.list.length} records.`,
          timeStamp: new Date(),
          requestType: "Touch",
        });
      }
    },
  },
  methods: {
    showMap() {
      let mapPath = this.getMaps.get(this.getDefaultLocation.toLowerCase())
      let floorName = this.$i18n.t('floors').filter(floor => floor.floorNumber === mapPath.mapFloor)
      this.$store.dispatch('openImageViewer', {
        name: mapPath.mapName,
        url: mapPath.mapImage,
        floor: floorName[0].floorName,
        showQR: false,
        qrUrl: ""
      })
    },
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      this.$store.commit('setRequestQna', false)
      this.$store.commit("setIsThinking", false)
      this.$store.commit("setSpeechRecognitionHandler", false);
      console.log("button", button);
    },
    onInputChange(input) {
      this.searchKeyword = input.target.value;
    },
    handleCourtLocation(location) {
      console.log('Location: ', location)
      this.$store.commit('setRequestQna', false)
      this.$store.commit("setIsThinking", false)
      this.$store.commit("setSpeechRecognitionHandler", false);
      window.setPlayerVolume(0)
      window.stopSpeech();
      this.$i18n.t("maps").forEach(mapper => {
        if (mapper.mapName.toLowerCase().trim() === location.toLowerCase().trim()) {
          var floorName = this.$i18n.t('floors').filter(floor => floor.floorNumber === mapper.mapFloor)
          this.$store.dispatch("openImageViewer", {
            name: mapper.mapName,
            url: mapper.mapImage,
            floor: floorName[0].floorName,
            showQR: false,
            qrUrl: ""
          });
          this.$store.dispatch("avatarSpeak", mapper.speech);
          this.tab = mapper.mapFloor - 1
        }
      })
      //this.$store.dispatch("requestQnA", location);
      //user session logging
      this.$store.commit("setTouchRequest", {
        module: "Todays Hearing",
        action: 'Show Map',
        response: location,
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
    customSort(a, b) {
      if (a.partyName === '' && b.partyName === '') {
        return 0;
      } else if (a.partyName === '') {
        return -1;
      } else if (b.partyName === '') {
        return 1;
      } else {
        return a.partyName.localeCompare(b.partyName);
      }
    },
    formatTime(inputTime) {

    }
  },
  filters: {
    dateAndTime(val) {
      if (!val) {
        return "-";
      }

      return 'Today - ' + moment(val, ['hmmA', 'h:mmA']).format('h:mm A')
      //return 'Today - ' + parsedTime.toLocaleString('en-US', options);
    },
    trimLength(val) {
      if (val === null || val === undefined) return val;
      if (val.length <= 30) {
        return val;
      }
      return `${val.substring(0, 23)}...`;
    },
  },
  mounted() {
    this.$store.dispatch('verifyFile').then(response => {
      if (response) {
        this.loading = false
      } else {
        this.$store.dispatch("avatarSpeak", "Error fetching hearing data. Please go to the Clerk's office for more information. ");
      }
    })
  }
};
</script>

<style>
.hg-theme-default {
  background-color: #bcbcbc !important;
  font-weight: bolder !important;
  font-size: x-large !important;
}
</style>
<i18n>
  {
    "en": {
        "placeHolder": "Tap to search by Full Name or Case Number",
        "noCase": "Can't find your case?"
      },
    "es":{
        "placeHolder": "Toque para buscar por nombre completo o n�mero de caso",
        "noCase": "¿No puedes encontrar tu caso?"
      }
  }
</i18n>